import PropTypes from 'prop-types';
import { useFeature, useFetch } from 'bv-hooks';
import { Spinner, ListLinks } from 'bv-components';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from 'session-selectors';
import ContactUsLink from './contact_us_link';
import InternalLink from './internal_link';
import { apiFetchContactUsConfigurations } from '../api';
import { composeContactUsLinkAdditionalProps, filterLinks } from '../helpers';

const linkTypes = {
  REQUEST_A_CALLBACK: InternalLink,
};

const ContactUs = ({ header, footer }) => {
  const [links, loading] = useFetch(apiFetchContactUsConfigurations);
  const loggedIn = useSelector(selectLoggedIn);
  const [intercomEnabled, intercomResolved] = useFeature('intercom_live_chat');

  if (loading || !intercomResolved) return <Spinner />;

  const liveChatLink = links.find((link) => link.action === 'LIVE_CHAT');
  if (liveChatLink) {
    liveChatLink.chatType = intercomEnabled ? 'intercom' : 'zendesk';
  }

  return (
    <>
      {header}
      <div className="contact-us">
        <ListLinks>
          {
            filterLinks(links, loggedIn).map((link) => {
              const LinkComponent = linkTypes[link.action] || ContactUsLink;

              return (
                <LinkComponent
                  key={link.id}
                  {...link}
                  {...composeContactUsLinkAdditionalProps(link)}
                  openInNewTab={link.open_in_new_tab}
                />
              );
            })
          }
        </ListLinks>
      </div>
      {footer}
    </>
  );
};

ContactUs.propTypes = {
  header: PropTypes.element,
  footer: PropTypes.element,
};

ContactUs.defaultProps = {
  header: null,
  footer: null,
};

export default ContactUs;
