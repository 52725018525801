import PropTypes from 'prop-types';

const ContactUsLink = ({
  href, target, handleClick, image, label, openInNewTab,
}) => (
  <li className="contact-us__item">
    <a
      className="contact-us__link"
      href={href}
      target={openInNewTab ? '_blank' : target}
      onClick={handleClick}
      rel="noreferrer"
    >
      <img src={image} alt={label} className="contact-us__icon" />
      {label}
    </a>
  </li>
);

ContactUsLink.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  handleClick: PropTypes.func,
  openInNewTab: PropTypes.bool.isRequired,
};

ContactUsLink.defaultProps = {
  href: null,
  target: null,
  handleClick: null,
};

export default ContactUsLink;
