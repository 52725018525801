import { useLocation } from 'react-router-dom';
import { ModalWindow } from 'bv-components';
import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';
import ContactUs from './contact_us';

const ContactUsApp = () => {
  const onBackClick = new URLSearchParams(useLocation().search).get('from') === 'questionnaire'
    ? () => setFragment('/account/verification_zone')
    : undefined;

  return (
    <ModalWindow title={t('javascript.kyc.core_strats.contact_us_title')} className="contact-us-modal" onBack={onBackClick}>
      <ContactUs />
    </ModalWindow>
  );
};

export default ContactUsApp;
