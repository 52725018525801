import PropTypes from 'prop-types';
import { Link } from 'bv-components';

const InternalLink = ({ href, image, label }) => (
  <li className="contact-us__item">
    <Link to={href} className="contact-us__link">
      <img src={image} alt={label} className="contact-us__icon" />
      {label}
    </Link>
  </li>
);

InternalLink.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
};

InternalLink.defaultProps = {
  href: null,
};

export default InternalLink;
