import { sortBy } from 'underscore';
import { zendesk } from 'bv-services';
import { show } from '@intercom/messenger-js-sdk';

const CONTACT_US_ACTIONS = {
  liveChat: 'LIVE_CHAT',
  email: 'EMAIL',
  requestCallback: 'REQUEST_A_CALLBACK',
  facebook: 'FACEBOOK',
  text: 'TEXT',
  twitter: 'TWITTER',
  url: 'URL',
  contactForm: 'CONTACT_FORM',
  helpCentre: 'HELP_CENTRE',
};

const LINK_DISPLAY_OPTIONS = {
  both: 'Both',
  loggedIn: 'Logged In',
  loggedOut: 'Logged Out',
};

const displayOptions = (isLoggedIn) => [
  LINK_DISPLAY_OPTIONS.both,
  isLoggedIn ? LINK_DISPLAY_OPTIONS.loggedIn : LINK_DISPLAY_OPTIONS.loggedOut,
];

export const composeContactUsLinkAdditionalProps = (link) => {
  switch (link.action) {
    case CONTACT_US_ACTIONS.liveChat:
      return {
        handleClick: link.chatType === 'intercom' ? show : zendesk.liveChat,
      };
    case CONTACT_US_ACTIONS.email: {
      const mailto = link.email_address;
      const subject = encodeURIComponent(link.email_subject);
      const body = encodeURIComponent(link.email_body);

      return { href: `mailto:${mailto}?subject=${subject}&body=${body}` };
    }
    case CONTACT_US_ACTIONS.requestCallback:
      return {
        href: '/pages/request-callback',
      };
    case CONTACT_US_ACTIONS.facebook:
    case CONTACT_US_ACTIONS.twitter:
      return {
        href: link.url_address,
        target: '_blank',
      };
    case CONTACT_US_ACTIONS.text:
      return {
        handleClick: () => window.location.assign(`sms:${link.sms_number}`),
      };
    case CONTACT_US_ACTIONS.url:
      return { href: link.url_address };
    case CONTACT_US_ACTIONS.contactForm:
      return {
        handleClick: () => zendesk.showService('contactForm'),
      };
    case CONTACT_US_ACTIONS.helpCentre:
      return {
        handleClick: () => zendesk.showService('helpCenter'),
      };
    default:
      return {};
  }
};

export const filterLinks = (links, loggedIn) => (
  sortBy(links, (link) => link.position)
    .filter((link) => displayOptions(loggedIn).includes(link.when_to_show))
);
